<template>
  <div class="iotManagePage">
    <div class="facilityBox">
      <div class="topbtns">
        <el-button type="success" size="medium" @click="flag.showAddDialog = !flag.showAddDialog" icon="el-icon-circle-plus-outline">添加</el-button>
        <el-button type="danger" size="medium" @click="delEquipments" icon="el-icon-remove-outline">删除</el-button>
      </div>
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="车牌" prop="VehicleCarNumber" label-width="96px">
          <el-input class="fromInp" v-model="manageForm.VehicleCarNumber" placeholder="输入车牌号" @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="物联设备编号" prop="equipNO">
          <el-input class="fromInp" v-model="manageForm.equipNO" placeholder="输入证件号" @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="司机手机" prop="DriverPhone" label-width="80px">
          <el-input class="fromInp" v-model="manageForm.DriverPhone" placeholder="输入司机手机号码" @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search('manageForm')" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm('manageForm')">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table 
        :data="tableData"
        @select="selectChange"
        @select-all="selectChange"
        :header-cell-style="{background:'#f0f0f0',color:'#666'}"
        v-loading="loading">
        <el-table-column type="selection" align="center" width="55" show-overflow-tooltip></el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EquipNO" label="设备编号" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EquipType" label="设备类型" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="OwnerName" label="设备所有人" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarNumberType" label="牌照类型" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarType" label="车辆类型" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverN" label="绑定司机" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EquipStatus" label="设备工作状态" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.EquipStatus == '空闲'" type="success">{{scope.row.EquipStatus}}</el-tag>
            <el-tag v-if="scope.row.EquipStatus == '故障'" type="danger">{{scope.row.EquipStatus}}</el-tag>
            <el-tag v-if="scope.row.EquipStatus == '工作中'" type="warning">{{scope.row.EquipStatus}}</el-tag>
            <el-tag v-if="scope.row.EquipStatus == '维修中'" type="info">{{scope.row.EquipStatus}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="FaultDesc" label="异常原因" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="操作" fixed="right"  width="210">
          <template slot-scope="scope" >
            <el-button type="success" v-if="scope.row.CarNumber == null && scope.row.DriverN == null" size="small" icon="el-icon-circle-check" @click="bindClick('bind',scope.row)">绑定</el-button>
            <el-button type="danger" v-if="scope.row.CarNumber != null || scope.row.DriverN != null" size="small" icon="el-icon-circle-close" @click="bindClick('unbind',scope.row)">解绑</el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">操作记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="margin-top:10px;">
        <el-pagination background 
          class="pagination" 
          @current-change="handleCurrentChange" 
          :current-page.sync="pagination.page" 
          :page-size="pagination.pagesize" 
          layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog class="vehicleType" :visible.sync="flag.showAddDialog" top="30vh" width="740px">
      <span slot="title" class="dialog-title">
        添加设备
      </span>
      <div class="body">
        <el-form class="addForm" :model="addForm" :rules="addRule" ref="addForm" :inline="true">
          <el-form-item label="设备类型" prop="EquipTypeID" label-width="116px">
            <el-select v-model="addForm.EquipTypeID" placeholder="选择设备类型" style="width:200px;">
              <el-option v-for="(item,index) in facilitylist" :key="index" :value="item.Code" :label="item.Name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物联设备编号" prop="EquipNO" label-width="120px">
            <el-input v-model="addForm.EquipNO" placeholder="输入证件号"></el-input>
          </el-form-item>
          <el-form-item :label="addForm.EquipTypeID == '02'?'车牌号':'司机手机号'" prop="BindID" label-width="116px">
            <el-input v-model="addForm.BindID" :placeholder="addForm.EquipTypeID == '02'?'输入车牌号':'输入司机手机号'"></el-input>
          </el-form-item>
          <el-form-item label="设备所有人" prop="EquipOwner" label-width="120px">
            <el-input v-model="addForm.EquipOwner" disabled placeholder="输入所有人"></el-input>
          </el-form-item>
          <el-form-item label="工作状态" prop="EquipStatus" label-width="116px">
            <el-select v-model="addForm.EquipStatus" placeholder="选择设备工作状态" style="width:200px;">
              <el-option v-for="(item,index) in facilitytype" :key="index" :value="item.Code" :label="item.Name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间" prop="EquipRegDate" label-width="120px">
            <el-date-picker disabled v-model="addForm.EquipRegDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期范围"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button size="medium" @click="flag.showAddDialog = !flag.showAddDialog">取消</el-button>
        <el-button type="primary" size="medium" @click="addfacility('addForm')">确认</el-button>
      </div>
    </el-dialog>
    <!-- 绑定/解绑弹窗 -->
    <el-dialog :visible.sync="flag.operation" class="vehicleType" top="25vh" width="740px">
      <span slot="title" class="dialog-title">
        {{flag.title}}
      </span>
      <div>
        <el-form :model="facilityInfo" inline>
          <el-form-item label="设备编号：" prop="EquipNO" label-width="120px">
            <el-input v-model="facilityInfo.EquipNO" disabled placeholder="输入所有人"></el-input>
          </el-form-item>
          <el-form-item label="设备类型：" prop="EquipType" label-width="120px">
            <el-input v-model="facilityInfo.EquipType" disabled placeholder="输入所有人"></el-input>
          </el-form-item>
          <el-form-item :label="facilityInfo.EquipType == '方众魔盒'?'车牌号：':'司机手机号：'" prop="EquipNO" label-width="120px">
            <el-input v-model="facilityValue" :placeholder="facilityInfo.EquipType == '方众魔盒'?'请输入车牌号':'请输入司机手机号'"></el-input>
          </el-form-item>
          <el-form-item label="设备归属：" prop="OwnerName" label-width="120px">
            <el-input v-model="facilityInfo.OwnerName" disabled placeholder="输入所有人"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 100%;" v-if="disabled">
        <el-form :model="facilityInfo">
          <el-form-item label="解绑原因：" prop="OwnerName" >
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              placeholder="请输入解绑原因，让我们更好服务您"
              v-model="textarea">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-alert
        title="温馨提示：请仔细输入绑定目标信息，如绑定错误，可以解绑重新绑定新目标。"
        type="warning"
        :closable="false">
      </el-alert>
      <div slot="footer" class="dialog-footer">
        <el-button @click="flag.operation = false">取 消</el-button>
        <el-button type="primary" @click="bindFacility()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {saveEquipments,equipmentsList,delEquipmentsInfo,BindUntieInfo} from '@/api/transport/ioManage/index';
import {getDataDict} from '@/api/common/common.js'
import { _getName } from '@/utils/storage'
import { mapGetters, mapMutations } from "vuex";
import { getGoodsOwnerInfo } from '@/api/goodsOwner/goodsOwnerManage/basicInfo'
import verify from '@/utils/verify.js'
export default {
  data(){
    return{
      //分页控件相关参数
      pagination: { 
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0,//总条数
      },
      // 表单校验
      addRule:{
        EquipTypeID: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
        EquipNO: [{ required: true, message: '请输入设备编号', trigger: 'change' }],
        // BindID: [{ required: true, message: '请输入', trigger: 'change' }],
        // EquipOwner: [{ required: true, message: '请输入设备所有人', trigger: 'change' }],
        EquipStatus: [{ required: true, message: '请选择工作状态', trigger: 'change' }],
        // EquipRegDate: [{ required: true, message: '请选择操作时间', trigger: 'change' }]
      },
      // 提交表单
      addForm: {
        EquipID:0,
        EquipTypeID:'',
        EquipOwner:'',
        EquipRegDate:new Date(),
        BindID:'',
        EquipNO:'',
        EquipStatus:'',
        OperatorID:'',
        Note:''
      },
      // 弹窗管理
      flag: {
        //是否显示添加设备信息
        showAddDialog: false,
        // 绑定&解绑弹窗
        operation:false,
        // 弹窗名称
        title:''
      },
      // 表格数据
      tableData:[],
      // 筛选条件数据
      manageForm:{
        VehicleCarNumber:'',
        equipNO:'',
        DriverPhone:'',
        pageIndex: 1, //当前所处的页码
        pageSize: 10, //每次请求的数量
      },
      // 设备类型
      facilitylist:[],
      // 设备状态
      facilitytype:[],
      // 删除设备数据
      dataAyy:[],
      // 设备信息
      facilityInfo:'',
      // 设备号
      facilityValue:'',
      // 解绑设备是否禁用输入框
      disabled:false,
      // 解绑原因
      textarea:'',
      loading:true
    }
  },
  created(){
    // 获取设备类型
    this.getDataDict(17);
    // 设备状态
    this.getDataDict(18);
    // 获取设备列表
    this.equipmentsList();
    getGoodsOwnerInfo({}).then(res => {
      if(res.enterPrise != null){
        this.addForm.EquipOwner = res.enterPrise.EnterpriseFullName
      }
    })
  },
  computed: {
    ...mapGetters(["getName"]),
  },
  watch:{
    'flag.showAddDialog'(val){
      if(val == false){
        this.addForm = {
          EquipID:0,
          EquipTypeID:'',
          EquipOwner:'',
          EquipRegDate:'',
          BindID:'',
          EquipNO:'',
          EquipStatus:'',
          OperatorID:'',
          Note:''
        }
        this.$refs['addForm'].resetFields()
      }
    }
  },
  methods:{
    // 绑定/解绑设备
    bindClick(type,row){
      this.facilityInfo = row
      if(type == 'bind'){
        this.flag.title = '绑定设备'
        this.facilityValue = ''
        this.disabled = false
      }else{
        this.flag.title = '解绑设备'
        this.facilityValue = row.EquipType == '方众魔盒'?row.CarNumber:row.DriverPhone
        this.disabled = true
      }
      this.flag.operation = true
    },
    // 绑定/解绑设备接口
    bindFacility(){
      let data = {}
      this.loading = true
      if(this.disabled == false){
        // 绑定设备
        data.type = 0;
        data.BindID = this.facilityValue;
        if(this.facilityInfo.EquipTypeID == '01'){
          if(verify._phone(data.BindID) == false){
            this.$message({
              showClose: true,
              message: '司机手机号输入有误，请重新输入',
              type: 'error'
            });
            return
          }
        }else{
          if(data.BindID == ''){
            this.$message({
              showClose: true,
              message: '车牌号不能为空',
              type: 'error'
            });
            return
          }
        }
        BindUntieInfo({id:this.facilityInfo.EquipID,BindID:data.BindID,type:data.type,Remark:this.textarea}).then(res=>{
          // 获取设备列表
          this.equipmentsList();
          this.flag.operation = false;
          this.textarea = ''
        })
      }else{
        // 解绑设备
        data.type = 1;
        data.BindID = this.facilityValue;
        if(this.facilityInfo.EquipTypeID == '01'){
          if(verify._phone(data.BindID) == false){
            this.$message({
              showClose: true,
              message: '司机手机号输入有误，请重新输入',
              type: 'error'
            });
            return
          }
        }else{
          if(data.BindID == ''){
            this.$message({
              showClose: true,
              message: '车牌号不能为空',
              type: 'error'
            });
            return
          }
        }
        if(this.textarea == ''){
          this.$message.error('请填写解绑原因');
          return
        }
        this.$confirm('是否确定解绑此设备?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          BindUntieInfo({id:this.facilityInfo.EquipID,BindID:data.BindID,type:data.type,Remark:this.textarea}).then(res=>{
            // 获取设备列表
            this.equipmentsList();
            this.flag.operation = false;
            this.textarea = ''
          })
        });
      }
    },
    // 全选/单选删除设备
    selectChange(val){
      let dataAyy = []
      val.forEach((itam,index,array) => {
        dataAyy.push(itam)
      });
      this.dataAyy = dataAyy
    },
    // 删除设备接口
    delEquipments(){
      if(this.dataAyy.length == 0){
        this.$message({
            type: 'warning',
            message: '请选择需要删除的设备'
          });  
      }else{
        this.loading = true
        this.$confirm('此操作将永久删除所选设备, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let delArray = []
          this.dataAyy.forEach((item,index)=>{
            delArray[index] = {EquipID:item.EquipID,BindID:item.BindID}
          })
          delEquipmentsInfo({Json:JSON.stringify(delArray)}).then(res=>{
            // 获取设备列表
            this.equipmentsList();
          })
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    // 条件搜索设备列表
    search(){
      this.manageForm.pageIndex = 1
      this.equipmentsList();
    },
    // 清空搜索条件
    resetForm(){
      this.loading = true
      this.manageForm = {
        bindID:'',
        equipNO:'',
        vehicleCarNumber:'',
        pageIndex: 1, //当前所处的页码
        pageSize: 10, //每次请求的数量
      }
      this.equipmentsList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true
      this.manageForm.pageIndex = e;
      this.equipmentsList();
    },
    // 获取设备列表
    equipmentsList(){
      equipmentsList({Json:JSON.stringify(this.manageForm)}).then(res=>{
        this.tableData = res.equipments
        this.pagination.total = Number(res.totalRowCount)
      }).finally(() => {
        this.loading = false;
      })
    },
    // 获取设备类型/设备状态
    getDataDict(type){
      getDataDict({type}).then(res=>{
        if(type == 17){
          // 设备类型
          this.facilitylist = res.patterSetInfo
        }else if(type == 18){
          // 设备状态
          this.facilitytype = res.patterSetInfo
        }
      })
    },
    // 添加设备
    addfacility(addForm){
      this.$refs[addForm].validate((valid) => {
        if (valid) {
          this.loading = true
          saveEquipments({Json:JSON.stringify(this.addForm)}).then(res=>{
            this.equipmentsList();
            this.flag.showAddDialog = false;
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
    //查看操作记录
    goFacility(item) {
      this.$router.push({ path: '/transport/iotManage/facility', query: { EquipID:item.EquipID,EquipTypeID:item.EquipTypeID,EquipNO:item.EquipNO,EquipType:item.EquipType,EquipStatus:item.EquipStatus} })
    }
  }
}
</script>

<style lang="scss" scoped>
.inpBox{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 10px 0 0 0;
  .dialogText{
    vertical-align: top;
    margin: 6px 10px 0 0;
  }
  .inp{
    width: 40%;
  }
}
.dialogBox{
  font-size: 16px;
  line-height: 28px;
  padding-left: 24.5%;
}
.iotManagePage{
  .topbtns{
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 90% !important;
    }
  }
  .choose {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  }
}
</style>